<template>
  <div>
    <v-snackbar
      v-model="showSnackBar"
      :timeout="2000"
      :color="snackBarColor"
      :elevation="24"
      transition="slide-x-reverse-transition"
      bottom
      right
      tile
    >
      <div class="text-center">{{ snackBarText }}</div>
    </v-snackbar>
    <div>
      <div v-if="loading" class="d-flex align-center justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-if="!loading && updatedItems">
        <template>
          <v-alert
            text
            outlined
            prominent
            color="info"
            type="info"
            class="mb-6"
          >
            Only clients with completed assessments are listed. <br />Select a
            client to view details and actions.
          </v-alert>

          <v-data-table
            :loading="tableLoading"
            loading-text="Loading Clients... Please wait"
            :headers="headers"
            :items="updatedItems"
            :options.sync="options"
            :server-items-length="serverItemsLength"
            :footer-props="{
              itemsPerPageOptions: itemsPerPage,
            }"
            :page.sync="page"
            @page-count="pageCount = $event"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <v-toolbar flat class="mb-4" height="auto">
                <div
                  class="
                    d-flex
                    flex-column flex-md-row
                    align-center
                    justify-center
                    flex-grow-1
                  "
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    clearable
                    single-line
                    hide-details
                    @input="fetchData"
                    class="flex-grow-1"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-data-footer
                    :pagination="pagination"
                    :options.sync="options"
                    @update:options="updateOptions"
                    items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                    :items-per-page-options="itemsPerPage"
                    style="border: none"
                    class="flex-grow-1"
                  />
                </div>
              </v-toolbar>
            </template>
            <template v-slot:item="{ item }">
              <tr
                :class="{ 'is-complete': item.scoreSurveyComplete }"
                @click.stop="handleRowClick(item)"
              >
                <td>
                  <div style="width: 48px" class="d-flex py-2">
                    <svg
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0.3 23.3 47.8 48"
                      xml:space="preserve"
                      style="height: 48px"
                    >
                      <component :is="item.avatarComponent" />
                    </svg>
                  </div>
                </td>
                <!-- <td>
                <small class="d-block">{{ item.id ? item.id : "No ID" }}</small>
              </td> -->
                <!-- <td>
                  <small>{{ item.email ? item.email : "--" }}</small>
                </td> -->
                <td>
                  <small>{{ item.firstName ? item.firstName : "--" }}</small>
                </td>
                <td>
                  <small>
                    {{ item.lastName ? item.lastName : "--" }}
                  </small>
                </td>
                <!-- <td class="text-center">
                  <a v-if="item.phone" :href="`tel: ${item.phone}`"
                    ><small>{{ item.phone }}</small></a
                  >
                  <template v-else>--</template>
                </td> -->
                <td>
                  <small class="d-block">
                    {{ item.createdAt ? formatDate(item.createdAt) : "--" }}
                  </small>
                </td>
                <!-- <td class="text-center">
                 <a
                  v-if="item.advisor"
                  href="#"
                  @click.prevent="handleOpenAdvisor(item)"
                  class="text-decoration-none"
                  >{{ item.advisor.vanityNames.items[0].vanityName
                  }}<br /><small>{{ item.advisorId }}</small></a
                > -->
                <!-- <template v-if="item.advisor">
                  <router-link
                    :to="{ path: '/admin/advisor/edit/' + item.advisor.id }"
                    target="_blank"
                  >
                    <small
                      >{{ item.advisor.firstName }} {{ item.advisor.lastName }}<br
                    /></small>
                  </router-link>
                  <small class="d-block grey--text text--darken-1">{{
                    item.advisor.id
                  }}</small>
                </template> 

            <span v-else>--</span> 
              </td>-->
                <td class="text-center">
                  <v-icon v-if="item.scoreSurveyComplete" color="success"
                    >mdi-check-bold</v-icon
                  >
                  <span v-else>--</span>
                </td>
                <td class="text-center">
                  <small>{{ item.riskScore ? item.riskScore : "--" }}</small>
                </td>
                <!-- <td class="text-center">
                <a
                  href="#"
                  v-if="item.scoreSurveyAnswers"
                  @click.prevent="handleOpenAnswers(item)"
                  class="text-decoration-none"
                  >{{ JSON.parse(item.scoreSurveyAnswers).length }}</a
                >
                <span v-else>--</span>
              </td> -->

                <td class="text-center">
                  <div class="d-flex justify-center align-center">
                    <v-tooltip top nudge-bottom="5">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.stop="handleCopyLink(item.id)"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          class="mr-1 text-center text-decoration-none"
                          :disabled="!item.scoreSurveyComplete"
                          ><v-icon small>mdi-content-copy</v-icon></v-btn
                        >
                      </template>
                      <span>Copy</span>
                    </v-tooltip>

                    <v-tooltip top nudge-bottom="5">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :href="getLink(linkPath, item.id)"
                          v-bind="attrs"
                          v-on="on"
                          target="_blank"
                          icon
                          small
                          class="mr-1 text-center text-decoration-none"
                          :disabled="!item.scoreSurveyComplete"
                          ><v-icon small>mdi-open-in-new</v-icon></v-btn
                        >
                      </template>
                      <span>Open</span>
                    </v-tooltip>

                    <v-tooltip top nudge-bottom="5">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click.stop="handleSelectProspectDelete(item)"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          small
                          class="text-center text-decoration-none"
                        >
                          <v-icon color="red" small> mdi-delete </v-icon></v-btn
                        >
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </template>
      </div>
    </div>
    <v-dialog v-model="showProspectDeleteDialog" max-width="800" width="75%">
      <v-card>
        <template v-if="loadingDeleteProspect">
          <v-card-title class="align-center justify-center">
            <div class="d-flex flex-column align-center justify-center py-4">
              <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
              ></v-progress-circular>
              <p class="mt-3">Deleting prospect...</p>
            </div>
          </v-card-title>
        </template>

        <template v-else>
          <v-card-title class="d-flex mb-4">
            <h3>Delete Client</h3>
            <v-btn
              icon
              @click="showProspectDeleteDialog = false"
              class="ml-auto"
            >
              <v-icon>mdi-close</v-icon></v-btn
            >
          </v-card-title>
          <v-card-text>
            <p>
              Are you sure you want to <strong>DELETE</strong> this client? All
              of the clients's information will be deleted. This operation
              cannot be undone.
            </p>
          </v-card-text>
          <v-card-actions class="mb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              text
              @click="
                showProspectDeleteDialog = false;
                selectedProspect = null;
              "
            >
              Cancel
            </v-btn>
            <v-btn color="primary" text @click="handleDeleteProspect()">
              Confirm Delete
            </v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import {
  onCreateProspect,
  onDeleteProspect,
  onUpdateProspect,
} from "@/graphql/subscriptions";
// import { deleteProspect } from "@/graphql/mutations";
import { mapGetters } from "vuex";
import linkBuilderMixin from "@/mixins/linkBuilderMixin";
import tableSearchPaginateMixin from "@/mixins/tableSearchPaginateMixin";
import { prospectAvatarComponent } from "@/services/prospectAgeGenderAvatar.js";

// import { listProspects } from "@/graphql/queries";

export default {
  mixins: [linkBuilderMixin, tableSearchPaginateMixin],
  data() {
    return {
      queryContext: "prospects",
      showProspectDeleteDialog: false,
      selectedProspect: null,
      linkPath: "your-score-report",
      loading: false,
      loadingDeleteProspect: false,
      headers: [
        {
          text: "",
          align: "center",
          value: "",
        },
        // {
        //   text: "Prospect ID",
        //   align: "start",
        //   value: "id",
        // },
        // { text: "Email", value: "email" },
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        // { text: "Phone", value: "phone", align: "center" },
        {
          text: "Created",
          align: "start",
          value: "createdAt",
        },
        // { text: "Advisor", value: "advisorId", align: "center" },
        {
          text: "Survey Complete",
          value: "scoreSurveyComplete",
          align: "center",
        },
        // {
        //   text: "Answers",
        //   value: "scoreSurveyAnswers",
        //   align: "center",
        //   sortable: false,
        // },
        // {
        //   text: "View Results",
        //   value: "scoreSurveyResults",
        //   align: "center",
        //   sortable: false,
        // },
        {
          text: "Risk Score",
          value: "riskScore",
          sortable: true,
          align: "center",
        },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      showSnackBar: false,
      snackBarText: "",
      snackBarColor: "info",
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    updatedItems() {
      const newItems = [];
      if (this.currentUser?.id) {
        this.items.forEach((item) => {
          if (item.scoreSurveyAnswers) {
            const answers = JSON.parse(item.scoreSurveyAnswers);

            const avatar = prospectAvatarComponent(answers);

            if (avatar) {
              item.avatarComponent = avatar;
            }

            if (item.scoreSurveyResults) {
              const parsedSurveyResults = JSON.parse(item.scoreSurveyResults);
              if (parsedSurveyResults.final_score) {
                item.riskScore = parsedSurveyResults.final_score;
              }
            }
          }

          // In case the query brings back other prospects, only show those for this advisor
          if (item.advisorId === this.currentUser?.id) {
            newItems.push(item);
          }
        });
      }

      return newItems;
    },
    // queryAdvisorId() {
    //   console.log("this.currentUser?.id", this.currentUser?.id);
    //   return this.currentUser?.id;
    // },
    queryFilter() {
      return {
        advisorId: {
          eq: this.currentUser?.id,
        },
        scoreSurveyComplete: {
          eq: true,
        },
        // email: { exists: false },
      };
    },
  },
  watch: {
    updatedItems: {
      handler(val) {
        // console.log("VAL", val);
        if (!val || (val.length >= 0 && this.currentUser?.id)) {
          this.loading = false;
        }
      },
      // deep: true,
    },
    // currentUser: {
    //   handler(val) {
    //     if (val.currentUser.id) {
    //       console.log("FETCH WITH USR");
    //       this.fetchData();
    //     }
    //   },
    // },
  },
  created() {
    this.loading = true;
    //Subscribe to changes
    API.graphql(graphqlOperation(onCreateProspect)).subscribe((sourceData) => {
      const newProspect = sourceData.value.data.onCreateProspect;
      if (newProspect && this.items) {
        // skip our own mutations and duplicates
        if (this.items.some((r) => r.id == newProspect.id)) return;
        this.items = [newProspect, ...this.items];
      }
    });
    API.graphql(graphqlOperation(onUpdateProspect)).subscribe((sourceData) => {
      const updatedProspect = sourceData.value.data.onUpdateProspect;
      if (updatedProspect && this.items) {
        if (this.items.some((r) => r.id == updatedProspect.id)) {
          const index = this.items.findIndex(
            (p) => p.id === updatedProspect.id
          );
          Object.assign(this.items[index], updatedProspect);
        }

        // this.items = [updatedProspect, ...this.items];
        // this.items = this.items.filter(
        //   (r) => r.id != updatedProspect.id
        // );
      }
    });
    API.graphql(graphqlOperation(onDeleteProspect)).subscribe((sourceData) => {
      const deletedProspect = sourceData.value.data.onDeleteProspect;
      if (deletedProspect && this.items) {
        this.items = this.items.filter((r) => r.id != deletedProspect.id);
      }
    });
  },
  mounted() {
    // this.fetchProspects();
  },
  methods: {
    async fetchProspects() {
      // Only fetch those of the advisor
      // Pass in the advisor id to filter
      fetch(process.env.VUE_APP_SCORE_API + "prospects", {
        method: "GET",
        // headers: {
        //   // Authorization: `Bearer ${jwt}`,
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(this.formData),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong loading clients", response);
          }
        })
        .then((data) => {
          if (data.success) {
            // console.log("data success", data);

            this.prospects = data.prospects;
          } else {
            console.log("Error data:", data);

            throw new Error(data.message);
          }

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);

          this.loading = false;
        });
    },
    async handleCopyLink(id) {
      try {
        const path = this.linkPath;
        const url = this.copyLink(path, id);

        if (url) {
          this.linkCopied = true;

          this.showSnackBar = true;
          this.snackBarText = "Client's Report Link Copied!";
          this.snackBarColor = "info";
        }
        setTimeout(() => {
          this.linkCopied = false;
        }, 3000);
      } catch ($e) {
        this.linkCopied = false;
      } finally {
        // this.dialogVanityNames = false;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("default", {
        dateStyle: "medium",
        timeStyle: "short",
      }).format(date);
    },
    handleRowClick(item) {
      console.log("row click", item);
      this.$router.push({
        path: `/prospect/${item.id}`,
        query: { surveyComplete: item.scoreSurveyComplete },
      });
    },
    handleSelectProspectDelete(item) {
      this.selectedProspect = item;
      this.showProspectDeleteDialog = true;
    },
    async handleDeleteProspect() {
      this.loadingDeleteProspect = true;
      const prospectId = this.selectedProspect.id;

      if (prospectId) {
        fetch(process.env.VUE_APP_SCORE_API + "prospect/" + prospectId, {
          method: "DELETE",
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error(
                "Something went wrong deleting prospect",
                response
              );
            }
          })
          .then((data) => {
            console.log("YO WAS DELEETED", data);
            if (data.success) {
              // console.log("delete success", data);
              this.selectedProspect = null;
              this.showProspectDeleteDialog = false;
              this.items = this.items.filter((r) => r.id != prospectId);

              this.loadingDeleteProspect = false;
            } else {
              console.log("Error data:", data);

              throw new Error(data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.loadingDeleteProspect = false;
          });

        // try {
        //   const { id } = prospect;
        //   // console.log("Prospect to delete!", prospect);
        //   await API.graphql(
        //     graphqlOperation(deleteProspect, {
        //       input: { id: id },
        //     })
        //   );
        //   console.log("Prospect deleted!");
        //   this.updateItems = this.updateItems.filter((r) => r.id != prospect.id);

        //   this.showSnackBar = true;
        //   this.snackBarColor = "success";
        //   this.snackBarText = `Prospect has been DELETED!`;
        // } catch (error) {
        //   console.log("Error deleting prospect...", error);
        // }
      }
    },

    getRowClass(item) {
      let color = "rs-table-row ";
      switch (item.temp) {
        case "hot":
          color += "rs-table-row--hot";
          break;
        case "warm":
          color += "rs-table-row--warm";
          break;
        case "cold":
          color += "rs-table-row--cold";
          break;
        default:
          break;
      }

      return color;
    },
  },
};
</script>

<style lang="scss">
tr {
  cursor: pointer;
}
.rs-table-row {
  td:first-child {
    border-left: 4px solid;
  }

  & {
    &--hot {
      td:first-child {
        border-left-color: #e15241;
      }
      &:hover {
        background-color: rgba(#e15241, 0.05) !important;
      }
    }
    &--warm {
      td:first-child {
        border-left-color: #f19d38;
      }
      &:hover {
        background-color: rgba(#f19d38, 0.05) !important;
      }
    }
  }
  &--cold {
    td:first-child {
      border-left-color: #54b9d1;
    }
    &:hover {
      background-color: rgba(#54b9d1, 0.05) !important;
    }
  }
}
</style>
