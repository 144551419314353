var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"timeout":2000,"color":_vm.snackBarColor,"elevation":24,"transition":"slide-x-reverse-transition","bottom":"","right":"","tile":""},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.snackBarText))])]),_c('div',[(_vm.loading)?_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}})],1):_vm._e(),(!_vm.loading && _vm.updatedItems)?_c('div',[[_c('v-alert',{staticClass:"mb-6",attrs:{"text":"","outlined":"","prominent":"","color":"info","type":"info"}},[_vm._v(" Only clients with completed assessments are listed. "),_c('br'),_vm._v("Select a client to view details and actions. ")]),_c('v-data-table',{attrs:{"loading":_vm.tableLoading,"loading-text":"Loading Clients... Please wait","headers":_vm.headers,"items":_vm.updatedItems,"options":_vm.options,"server-items-length":_vm.serverItemsLength,"footer-props":{
            itemsPerPageOptions: _vm.itemsPerPage,
          },"page":_vm.page},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(ref){
          var pagination = ref.pagination;
          var options = ref.options;
          var updateOptions = ref.updateOptions;
return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"flat":"","height":"auto"}},[_c('div',{staticClass:"\n                  d-flex\n                  flex-column flex-md-row\n                  align-center\n                  justify-center\n                  flex-grow-1\n                "},[_c('v-text-field',{staticClass:"flex-grow-1",attrs:{"append-icon":"mdi-magnify","label":"Search","clearable":"","single-line":"","hide-details":""},on:{"input":_vm.fetchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-data-footer',{staticClass:"flex-grow-1",staticStyle:{"border":"none"},attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","items-per-page-options":_vm.itemsPerPage},on:{"update:options":[function($event){options=$event},updateOptions]}})],1)])]}},{key:"item",fn:function(ref){
          var item = ref.item;
return [_c('tr',{class:{ 'is-complete': item.scoreSurveyComplete },on:{"click":function($event){$event.stopPropagation();return _vm.handleRowClick(item)}}},[_c('td',[_c('div',{staticClass:"d-flex py-2",staticStyle:{"width":"48px"}},[_c('svg',{staticStyle:{"height":"48px"},attrs:{"version":"1.1","id":"Layer_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0.3 23.3 47.8 48","xml:space":"preserve"}},[_c(item.avatarComponent,{tag:"component"})],1)])]),_c('td',[_c('small',[_vm._v(_vm._s(item.firstName ? item.firstName : "--"))])]),_c('td',[_c('small',[_vm._v(" "+_vm._s(item.lastName ? item.lastName : "--")+" ")])]),_c('td',[_c('small',{staticClass:"d-block"},[_vm._v(" "+_vm._s(item.createdAt ? _vm.formatDate(item.createdAt) : "--")+" ")])]),_c('td',{staticClass:"text-center"},[(item.scoreSurveyComplete)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('span',[_vm._v("--")])],1),_c('td',{staticClass:"text-center"},[_c('small',[_vm._v(_vm._s(item.riskScore ? item.riskScore : "--"))])]),_c('td',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-tooltip',{attrs:{"top":"","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 text-center text-decoration-none",attrs:{"icon":"","small":"","disabled":!item.scoreSurveyComplete},on:{"click":function($event){$event.stopPropagation();return _vm.handleCopyLink(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy")])]),_c('v-tooltip',{attrs:{"top":"","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 text-center text-decoration-none",attrs:{"href":_vm.getLink(_vm.linkPath, item.id),"target":"_blank","icon":"","small":"","disabled":!item.scoreSurveyComplete}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Open")])]),_c('v-tooltip',{attrs:{"top":"","nudge-bottom":"5"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-center text-decoration-none",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleSelectProspectDelete(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red","small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)])])]}}],null,false,1444036430)})]],2):_vm._e()]),_c('v-dialog',{attrs:{"max-width":"800","width":"75%"},model:{value:(_vm.showProspectDeleteDialog),callback:function ($$v) {_vm.showProspectDeleteDialog=$$v},expression:"showProspectDeleteDialog"}},[_c('v-card',[(_vm.loadingDeleteProspect)?[_c('v-card-title',{staticClass:"align-center justify-center"},[_c('div',{staticClass:"d-flex flex-column align-center justify-center py-4"},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"primary","indeterminate":""}}),_c('p',{staticClass:"mt-3"},[_vm._v("Deleting prospect...")])],1)])]:[_c('v-card-title',{staticClass:"d-flex mb-4"},[_c('h3',[_vm._v("Delete Client")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"icon":""},on:{"click":function($event){_vm.showProspectDeleteDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',[_vm._v(" Are you sure you want to "),_c('strong',[_vm._v("DELETE")]),_vm._v(" this client? All of the clients's information will be deleted. This operation cannot be undone. ")])]),_c('v-card-actions',{staticClass:"mb-4"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.showProspectDeleteDialog = false;
              _vm.selectedProspect = null;}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.handleDeleteProspect()}}},[_vm._v(" Confirm Delete ")])],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }