export function prospectAvatarComponent(answers) {
  let avatar;

  const genderObj = answers.find((a) => a.param === "gender");
  const gender = genderObj?.value;
  const yearBornObj = answers.find((a) => a.param === "year_born");
  const date = new Date();
  const age = date.getFullYear() - yearBornObj?.value;

  if (gender === "Female" && age <= 55) {
    avatar = "Female55Avatar";
  } else if (gender === "Female" && age > 55) {
    avatar = "Female56Avatar";
  } else if (gender === "Male" && age <= 55) {
    avatar = "Male55Avatar";
  } else if (gender === "Male" && age > 55) {
    avatar = "Male56Avatar";
  }

  if (avatar) {
    return () => import(`@/components/reportAnimationAvatars/${avatar}.vue`);
  }
}
